<template>
    <div>
        <div class='panel panel-default'>
            <div class='panel-heading'></div>
            <div class='row panel-body'>
                <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                    <label>Date From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.dateFrom.val" />
                </div>
                <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                    <label>Date To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.dateTo.val" />
                </div>
                <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                    <label>CDI Location</label>
                    <select class="cdi_select form-control" v-model="state.optionFields.cdiLocationID.val">
                        <option value="0">All</option>
                        <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                    </select>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import DatePicker from "@/components/utils/DatePicker";


    export default {
        name: "Options",
        components: {
            DatePicker
        },

        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                },
                showNotes: false
            }
        },
        methods: {
            toggleMoreFilters: function() {
                this.toggleHide = !this.toggleHide;
            }
        }
    }
</script>

<style scoped>

</style>