<template>
    <div>
        <h1>Labor Rate (Sales)</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <ResultsLabor/>
        </form>
    </div>

</template>

<script>

import {store} from '@/store/Report.store.js';
import Options from './labor_rate_opt.vue';
import ResultsLabor from './utils/Results_Labor';
import Fields from './utils/Fields.vue'

export default {
    name: "labor_rate",
    components: {
        Options,
        ResultsLabor,
        Fields
    },

    data() {
        return {
            cdi_id: '',
            state: store.state,
            optionFields: {
                dateFrom: {val: new Date().toLocaleDateString(), qsField: '[datetimes][timestamp_from]'},
                dateTo: {val: new Date().toLocaleDateString(), qsField: '[datetimes][timestamp_to]'},
                cdiLocationID: {val: 0, qsField: '[numbers][cdi_location_id]'},
            },
            groupByFields: {},
            dataFields: {
                timestamp: { label: 'Time Stamp', checked: true },
                cdi_location_id: { label: 'Location', checked: true },
                labor_goal: { label: 'Labor Rate Goal', checked: true },
                scheduler_remaining: { label: 'Scheduler Remaining', checked: false },
                projected_labor_remaining: { label: 'Labor Remaining', checked: false },
                total_scheduled_sales: { label: 'Total Scheduled Sales', checked: false },
                daily_labor_available: { label: 'Daily Labor Available', checked: false },
                labor_consumed: { label: 'Labor Consumed', checked: false },
                labor_remaining_actual: { label: 'Labor Remaining', checked: false },
                over_under_goal: { label: 'Over/Under Labor Goal', checked: false },
                sales_shipped_total: { label: 'Sales Shipped Today', checked: false },
                labor_rate: { label: 'Labor Rate', checked: true },
            }
        }
    },
    methods: {
        runReport: function () {
            store.runReport();
        }
    },
    created() {
        this.$appStore.setTitle(['Labor Rate (Sales)']);
        this.$set(store.state.results, this.$options.name, '');
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
    }
}
</script>

<style scoped>

</style>