<template>
  <div>
    <div
        @keypress.enter="emitEnterKeyPress($event.target)"
        @click="clickEvent($event.target)"
        class="table table-responsive results"
        id=""
        v-html="transformedData"
    ></div>
  </div>
</template>

<script>
import { store } from '@/store/Report.store.js'
export default {
  name: "Results",
  data() {
    return {
      state: store.state,
      transposedHtml: '',
    }
  },
  computed: {
    transformedData() {
      return this.transposeTable(this.state.results[this.state.report].html);
    }
  },
  methods: {
    transposeTable(htmlString) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const table = doc.querySelector('table');

      if (!table) {
        return '';
      }

      const rows = Array.from(table.rows);
      const transposedRows = [];

      let dataRowsPresent = rows.some(row => Array.from(row.cells).some(cell => cell.tagName === 'TD' && cell.textContent.trim() !== ''));

      if (!dataRowsPresent) {
        return '';
      }

      const maxCols = rows.reduce((max, row) => Math.max(max, row.cells.length), 0);

      for (let colIndex = 0; colIndex < maxCols; colIndex++) {
        const newRow = doc.createElement('tr');

        rows.forEach((row) => {
          const cell = row.cells[colIndex];
          if (cell) {
            newRow.appendChild(cell.cloneNode(true));
          } else {
            newRow.appendChild(doc.createElement('td'));
          }
        });

        transposedRows.push(newRow);
      }

      const newTable = doc.createElement('table');
      newTable.className = 'table';
      transposedRows.forEach(row => newTable.appendChild(row));

      return newTable.outerHTML;
    },
    updateTransposedHtml() {
      const originalHtml = this.state.results[this.state.report]?.html;
      if (originalHtml) {
        this.transposedHtml = this.transposeTable(originalHtml);

      }
    },
    clickEvent: function(el) {
      if (el.hasAttribute('cdi_value') && el.className.split(' ').indexOf('sortable') != -1) {
        store.sortReport(el.getAttribute('cdi_value').trim());
      } else {
        this.$emit('clicked', el);
      }
    },
    emitEnterKeyPress(el) {
      this.$emit('enterKeyPressed', el);
    }
  },
  mounted() {
    this.updateTransposedHtml();
  }
}
</script>

<style scoped>
.table.table-responsive{
  overflow-y:auto!important;
}
#reportResults {
  border: #cecece;
  border-style: solid;
  border-width: 1px;
  max-height: 80vh;
}
</style>



